import { useQuery } from '@apollo/client';
import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { createContext } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import React from 'react';

import AppleIcon from '../assets/icons/providers/apple-icon.svg';
import GoogleIcon from '../assets/icons/providers/google-icon.svg';
import useStorage from '../hooks/useStorage';
import firebase from '../services/firebase';
import { handleToken } from '../state/apolloClient';
import CurrentUserQuery from '../state/modules/user/CurrentUserQuery';
import { AFFILIATE_ADMIN_PAGES, COMPANY_ADMIN_PAGES } from '../utils/constants';
import { COMPANY_AGREEMENT_TYPES } from '../utils/constants';
import { DEFAULT_ROUTES } from '../utils/constants';
import { STAFF_PAGES } from '../utils/constants';

window.firebase = firebase;

const authContext = createContext();

export function AuthProvider({ children }) {
  const authHook = useAuthHook();
  return (
    <authContext.Provider value={authHook}>{children}</authContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(authContext);
};

export const externalProviders = [
  {
    name: 'Google',
    icon: GoogleIcon,
    login: new firebase.auth.GoogleAuthProvider(),
  },
  {
    name: 'Apple',
    icon: AppleIcon,
    login: new firebase.auth.OAuthProvider('apple.com'),
  },
];

function useAuthHook() {
  const [hasToken, setHasToken] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [authTime, setAuthTime] = useStorage('authTime', null);
  const [currentUser, setCurrentUser] = useState(null);
  const [defaultRoute, setDefaultRoute] = useState(DEFAULT_ROUTES.anonymous);
  const [isAffiliate, setIsAffiliate] = useState(false);
  const [isCompanyAdmin, setIsCompanyAdmin] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [loading, setLoading] = useState(true);

  const { data } = useQuery(CurrentUserQuery, {
    skip: !hasToken,
  });

  function setLoggedOut() {
    return firebase.auth().signOut();
  }

  async function signInWithProvider(provider) {
    const credential = await firebase.auth().signInWithPopup(provider);
    const idToken = await credential.user.getIdToken();
    handleToken(idToken);
    setHasToken(true);
    return credential;
  }

  function getAllowedRoutes() {
    return [
      ...(isStaff ? Object.keys(STAFF_PAGES) : []),
      ...(isCompanyAdmin && !isAffiliate
        ? Object.keys(COMPANY_ADMIN_PAGES)
        : []),
      ...(isCompanyAdmin && isAffiliate
        ? Object.keys(AFFILIATE_ADMIN_PAGES)
        : []),
    ];
  }

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user, error) => {
      if (user) {
        setAuthUser(user);
        setAuthTime(new Date(user.metadata.lastSignInTime).toString());
        user.getIdToken().then((t) => {
          handleToken(t);
          setHasToken(true);
        });
      } else {
        setDefaultRoute(DEFAULT_ROUTES.anonymous);
        setAuthUser(null);
        handleToken(null);
        setHasToken(false);
        setCurrentUser(null);
        setIsStaff(false);
        setIsCompanyAdmin(false);
        setIsAffiliate(false);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, [setAuthTime]);

  useEffect(() => {
    if (!hasToken || !data) {
      return;
    }
    const isStaff = R.pathOr(false, ['current_user', 'is_staff'], data);
    const company = R.pathOr(null, ['current_user', 'company'], data);
    const isAffiliate =
      R.pathOr(null, ['agreement_type'], company) ===
      COMPANY_AGREEMENT_TYPES.affiliate;
    const companyRoles = R.pathOr([], ['current_user', 'company_roles'], data);
    const isCompanyAdmin = !RA.isUndefined(
      companyRoles.find((item) => item.type === 'admin')
    );
    if (isCompanyAdmin) {
      setDefaultRoute(DEFAULT_ROUTES.company_admin);
    } else if (isStaff) {
      setDefaultRoute(DEFAULT_ROUTES.staff);
    }
    setCurrentUser(R.pathOr(null, ['current_user'], data));
    setIsStaff(isStaff);
    setIsCompanyAdmin(isCompanyAdmin);
    setIsAffiliate(isAffiliate);
    setLoading(false);
  }, [data, hasToken]);

  return {
    authTime,
    authUser,
    currentUser,
    defaultRoute,
    externalProviders,
    isAffiliate,
    isCompanyAdmin,
    isStaff,
    loading,
    getAllowedRoutes,
    setLoggedOut,
    signInWithProvider,
  };
}
